import React, { useContext, useEffect, useMemo } from 'react'
import * as classes from './Header.module.scss'
import cn from 'classnames'
import { Link, navigate } from 'gatsby'
import IconLogo from '../../icons/IconLogo'
import { FormContext } from '../../../context/index'
import anime from 'animejs'
import { getHrefToHome } from '../../../utils/getHrefToHome'
import { useDataHeaderByHy } from './useDataHeaderByHy'
import { useDataHeaderByEn } from './useDataHeaderByEn'
import { deleteHtmlTag } from '../../../utils/deleteHtmlTag'

function Header({ light, lang, page404 }) {
    const { showModal, onOpenModal, onCloseModal, animationStart, setAnimationStart } = useContext(FormContext)
    const hrefHome = getHrefToHome(lang)
    const urlToNewLanguagePage = lang === 'hy' ? `/en/` : `/`
    const menyByHY = useDataHeaderByHy()
    const menyByEN = useDataHeaderByEn()
    const dataAll = lang === 'hy' ? menyByHY : menyByEN
    const menuHeader = lang === 'hy' ? menyByHY.menu.nodes : menyByEN.menu.nodes

    const textButtonToggleLang = useMemo(() => deleteHtmlTag(dataAll.language.value, 'p'), [])

    const menuMain = useMemo(
        () =>
            menuHeader.map((item) => {
                const parent = menuHeader.filter((itemParent) => itemParent?.parentSection?.title === item.title)

                return { ...item, parent }
            }),
        []
    )

    const menuMainResult = useMemo(() => menuMain.filter(({ parentSection }) => !parentSection), [])

    const onClickCompanyLink = (event, name) => {
        if (name !== 'Company') {
            return
        }

        const url = lang === 'hy' ? `/company/about/` : `/en/company/about/`

        event.preventDefault()
        navigate(url)
    }

    const handlerToggleMenu = () => {
        if (showModal === 'menu') {
            return onCloseModal()
        }

        onOpenModal('menu')
    }

    const onGoToNewLanuageUrl = () => {
        let newUrl = ''

        if (lang === 'en') {
            newUrl = window.location.pathname.substring(3).trim()
            navigate(`${newUrl}`)
        } else {
            newUrl = window.location.pathname
            navigate(`/en${newUrl}`)
        }
    }

    const onChangeLanguage = () => {
        if (showModal === 'menu') {
            onCloseModal()
            onGoToNewLanuageUrl()

            // реализация с переходом на главную
            // navigate(urlToNewLanguagePage)
            return
        }

        onGoToNewLanuageUrl()

        // реализация с переходом на главную
        // navigate(urlToNewLanguagePage)
    }

    useEffect(() => {
        if (animationStart) {
            setAnimationStart(false)

            // Анимация хедера (лого, пункты меню, выбор языка)
            if (window.innerWidth >= 1024) {
                const logo = document.querySelector(`.${classes.Logo}`)
                const menuItems = document.querySelectorAll(`.${classes.LinkWrap}`)
                const lang = document.querySelector(`.${classes.Lang}`)

                if (logo) {
                    logo.style.transition = 'none'
                    logo.style.opacity = 0
                    logo.style.transform = 'translateY(-33px)'

                    anime({
                        targets: logo,
                        duration: 600,
                        delay: 750,
                        easing: 'cubicBezier(0, 0, 0.03, 1.01)',
                        opacity: [0, 1],
                        translateY: [-33, 0]
                    }).finished.then(() => logo.removeAttribute('style'))
                }

                if (menuItems) {
                    for (const item of menuItems) {
                        item.style.transition = 'none'
                        item.style.opacity = 0
                        item.style.transform = 'translateY(-47px)'
                    }

                    anime({
                        targets: menuItems,
                        duration: 600,
                        delay: (el, i) => 850 + 50 * i,
                        easing: 'cubicBezier(0, 0, 0.03, 1.01)',
                        opacity: [0, 1],
                        translateY: [-47, 0]
                    }).finished.then(() => {
                        for (const item of menuItems) {
                            item.removeAttribute('style')
                        }
                    })
                }

                if (lang) {
                    lang.style.transition = 'none'
                    lang.style.opacity = 0
                    lang.style.transform = 'translateY(-30px)'

                    anime({
                        targets: lang,
                        duration: 600,
                        delay: 1100,
                        easing: 'cubicBezier(0, 0, 0.03, 1.01)',
                        opacity: [0, 1],
                        translateY: [-30, 0]
                    }).finished.then(() => lang.removeAttribute('style'))
                }
            } else {
                const burger = document.querySelector(`.${classes.Burger}`)
                const logo = document.querySelector(`.${classes.Logo}`)
                const lang = document.querySelector(`.${classes.Lang}`)

                if (burger) {
                    burger.style.transition = 'none'
                    burger.style.opacity = 0
                    burger.style.marginTop = '-30px'

                    anime({
                        targets: burger,
                        duration: 600,
                        delay: 750,
                        easing: 'cubicBezier(0, 0, 0.03, 1.01)',
                        opacity: [0, 1],
                        marginTop: [-30, 0]
                    }).finished.then(() => burger.removeAttribute('style'))
                }

                if (logo) {
                    logo.style.transition = 'none'
                    logo.style.opacity = 0
                    logo.style.marginTop = '-33px'

                    anime({
                        targets: logo,
                        duration: 600,
                        delay: 900,
                        easing: 'cubicBezier(0, 0, 0.03, 1.01)',
                        opacity: [0, 1],
                        marginTop: [-33, 0]
                    }).finished.then(() => logo.removeAttribute('style'))
                }

                if (lang) {
                    lang.style.transition = 'none'
                    lang.style.opacity = 0
                    lang.style.marginTop = '-30px'

                    anime({
                        targets: lang,
                        duration: 600,
                        delay: 1050,
                        easing: 'cubicBezier(0, 0, 0.03, 1.01)',
                        opacity: [0, 1],
                        marginTop: [-30, 0]
                    }).finished.then(() => lang.removeAttribute('style'))
                }
            }
        }
    }, [animationStart])

    return (
        <header className={cn(classes.Header, { [classes.Light]: light })}>
            <div
                className={cn(classes.Burger, {
                    [classes.Active]: showModal === 'menu'
                })}
                onClick={handlerToggleMenu}
            >
                <span className={classes.BurgerLine} />
                <span className={classes.BurgerLine} />
                <span className={classes.BurgerLine} />
            </div>

            <Link to={page404 ? '/' : hrefHome}>
                <IconLogo className={cn(classes.Logo, { [classes.OpenMenu]: showModal === 'menu' })} />
            </Link>

            <nav className={classes.Menu}>
                {menuMainResult.map((item, index) => (
                    <div className={cn(classes.LinkWrap, 'header-linkWrap', { [classes.IsParent]: item.parent.length })} key={index}>
                        <Link
                            activeClassName={classes.Active}
                            to={`${item.url}`}
                            partiallyActive={true}
                            onClick={(e) => onClickCompanyLink(e, item.title)}
                        >
                            {item.title}
                        </Link>

                        {item.parent.length ? (
                            <div className={classes.DropDown}>
                                <ul>
                                    {item.parent.map((parentItem, parentIndex) => (
                                        <li key={parentIndex}>
                                            <Link activeClassName={classes.ActiveParent} to={`${parentItem.url}`} partiallyActive={true}>
                                                {parentItem.title}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : null}

                        <div className={classes.Overlay} />
                    </div>
                ))}
            </nav>

            <button className={cn(classes.Lang, { [classes.Active]: showModal === 'menu' })} onClick={onChangeLanguage}>
                {textButtonToggleLang}
            </button>
        </header>
    )
}

export default Header
